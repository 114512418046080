<template>
  <svg class="spinner icon-md fill-current" :class="color"><use xlink:href="/assets/icons.svg#spinner" /></svg>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    color: {
      type: String,
      required: false,
      default: 'text-white'
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  animation: spin 2s infinite linear;
}
</style>
