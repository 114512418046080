<template>
  <div
    class="brand-stocking"
    :style="{ backgroundImage: `url(${item.coverImage.filename})` }"
  >
    <div class="container">
      <div class="brand-stocking__content">
        <i class="brand-stocking__icon" :class="item.brandIcon" />
        <h1 class="text-xl sm:text-above-h2 leading-lg tracking-lg mb-9.5">
          {{ $t(item.title) }}
        </h1>
        <p class="brand-stocking__description" v-html="item.description"></p>
        <div class="brand-stocking__actions" v-if="!item.unavailable">
          <div class="sm:w-1/2 sm:pr-3">
            <p class="brand-stocking__action-title">
              {{ $t(item.aboutButtonTitle) }}
            </p>
            <button-full
              class="mt-4 sm:mt-0 pr-5 pl-10"
              @click.native="goTo(item.aboutButtonLink.cached_url)"
            >
              <span>
                {{ $t(item.aboutButtonText) }}
              </span>
              <i class="ss-gizmo checkout-icon ss-right text-h5" />
            </button-full>
          </div>
          <div class="mt-6 sm:w-1/2 sm:mt-0 sm:pl-3">
            <p class="brand-stocking__action-title">
              {{ $t(item.getAccessButtonTitle) }}
            </p>
            <button-full
              class="mt-4 sm:mt-0 pr-5 pl-10"
              @click.native="getBrandAccess"
              :disabled="submitted"
            >
              <span>
                {{ $t(item.getAccessButtonText) }}
              </span>
              <i class="ss-gizmo checkout-icon ss-right text-h5" />
            </button-full>
          </div>
        </div>
        <div class="brand-stocking__actions unavailable" v-else>
          <div class="mt-6 sm:w-1/2 sm:mt-0 sm:pl-3 m-auto">
            <p class="brand-stocking__action-title">
              {{ $t(item.getUnavailableButtonTitle) }}
            </p>
            <button-full
              class="mt-4 sm:mt-0 pr-5 pl-10"
              @click.native="goTo(item.getUnavailableButtonLink.cached_url)"
            >
              <span>
                {{ $t(item.getUnavailableButtonText) }}
              </span>
              <i class="ss-gizmo checkout-icon ss-right text-h5" />
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import ButtonFull from 'theme/components/theme/ButtonFull'
import AddressBook from 'theme/mixins/blocks/AddressBook'

export default {
  name: 'BrandStocking',
  extends: Blok,
  components: {
    ButtonFull
  },
  mixins: [
    AddressBook
  ],
  data () {
    return {
      submitted: false
    }
  },
  methods: {
    goTo (link) {
      window.open(link)
    },
    getBrandAccess () {
      if (!this.billingAddress) return

      const address = {
        first_name: this.billingAddress.firstName,
        last_name: this.billingAddress.lastName,
        company: [this.billingAddress.firstName, this.billingAddress.lastName].join(' ').trim() || this.billingAddress.company,
        phone: this.billingAddress.phone,
        email: this.currentUser.email,
        existing_customer: 1,
        address: this.billingAddress.street,
        address2: this.billingAddress.house || '',
        city: this.billingAddress.city,
        state: this.billingAddress.region,
        country: this.billingAddress.country,
        postal_code: this.billingAddress.postcode,
        stylist_type: 0,
        comments: `LBP Pro Automated Request: ${this.item.title}`,
        source: 'LBP Pro'
      }

      this.$store.dispatch('brands/getBrandAccess', {
        data: address,
        referer: this.item.aboutButtonLink.cached_url
      }).then(() => {
        this.submitted = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-stocking {
  @apply relative bg-cover bg-center bg-no-repeat;
  min-height: calc(100vh - 119px);

  @screen lg {
    min-height: calc(100vh - 245px);
  }

  @screen xl {
    min-height: calc(100vh - 295px);
  }

  &:before,
  &:after {
    @apply absolute block top-0 left-0 w-full h-full;
    content: '';
  }

  &:before {
    background-color: rgba(0, 0, 0, .4);
  }

  &:after {
    @apply bg-left-top bg-repeat z-2;
    background-image: url('/assets/pattern-img-tile.png');
  }

  &__content {
    @apply relative text-white text-center mx-auto py-10 z-3;
    max-width: 640px;

    @screen sm {
      @apply pt-30 pb-20;
    }
  }

  &__icon {
    @apply relative block text-above-h2 text-brown-3 pb-15;

    &:after {
      @apply absolute bg-brown-3;
      bottom: 30px;
      content: '';
      height: 1px;
      left: 50%;
      margin-left: -50px;
      width: 100px;
    }
  }

  &__description {
    @apply mx-auto mb-9.5;
    max-width: 550px;
  }

  &__actions {
    @screen sm {
      @apply flex justify-between;
    }

    &::v-deep {
      button {
        @apply border border-primary2 text-primary2 w-full;

        &:hover,
        &:focus {
          @apply bg-white text-black;
        }

        & > span {
          @apply flex justify-between items-center w-full;
        }
      }
    }
  }

  &__action-title {
    @apply font-serif font-normal italic text-2h6 mb-2;

    @screen sm {
      @apply text-left;

      &.unavailable {
        @apply text-center;
      }
    }
  }
}
</style>
