import toString from 'lodash-es/toString'

export default {
  data () {
    return {
      currentUser: Object.assign({}, this.$store.state.user.current),
      billingAddress: null,
      shippingAddress: null,
      additionalAddresses: []
    }
  },
  beforeMount () {
    this.$bus.$on('user-after-loggedin', this.onLoggedIn)
  },
  beforeDestroy () {
    this.$bus.$off('user-after-loggedin', this.onLoggedIn)
  },
  mounted () {
    this.getAddresses()
  },
  methods: {
    onLoggedIn () {
      this.currentUser = Object.assign({}, this.$store.state.user.current)
      this.getAddresses()
    },
    getAddresses () {
      if (this.currentUser) {
        for (let i = 0; i < this.currentUser.addresses.length; i++) {
          const address = {
            id: this.currentUser.addresses[i].id,
            firstName: this.currentUser.addresses[i].firstname,
            lastName: this.currentUser.addresses[i].lastname,
            company: this.currentUser.addresses[i].hasOwnProperty('company') ? this.currentUser.addresses[i].company : '',
            street: this.currentUser.addresses[i].street[0],
            house: this.currentUser.addresses[i].street[1],
            city: this.currentUser.addresses[i].city,
            postcode: this.currentUser.addresses[i].postcode,
            region: this.currentUser.addresses[i].region.region ? this.currentUser.addresses[i].region.region : '',
            country: this.currentUser.addresses[i].country_id,
            phone: this.currentUser.addresses[i].telephone,
            fax: this.currentUser.addresses[i].hasOwnProperty('fax') ? this.currentUser.addresses[i].fax : ''
          }

          let isAdditional = true

          if (this.currentUser.hasOwnProperty('default_billing') &&
            toString(this.currentUser.addresses[i].id) === toString(this.currentUser.default_billing)) {
            this.billingAddress = address
            isAdditional = false
          }
          if (this.currentUser.hasOwnProperty('default_shipping') &&
            toString(this.currentUser.addresses[i].id) === toString(this.currentUser.default_shipping)) {
            this.shippingAddress = address
            isAdditional = false
          }
          if (isAdditional) {
            this.additionalAddresses.push(address)
          }
        }
      }
    }
  }
}
