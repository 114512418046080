<template>
  <component class="inline-block slow-color-change whitespace-no-wrap outline-none focus:outline-none rounded-0 hover:bg-primary2 border-0 text-white text-center text-h5 uppercase tracking-sm"
    :class="[`bg-${color}`, { 'pointer-events-none opacity-50': disabled }, (small ? 'py-1 px-5' : 'px-10 py-4')]"
    :type="link ? '' : type" :is="link ? 'router-link' : 'button'" :to="localizedRoute(link)" :disabled="disabled">
    <span :class="(loading) ? 'hidden' : ''">
      <slot>
        Button
      </slot>
    </span>
    <loader v-if="loading" />
  </component>
</template>
<script>
import focusClean from 'theme/components/theme/directives/focusClean'
import Loader from 'theme/components/theme/Loader'
export default {
  name: 'ButtonFull',
  directives: {
    focusClean
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    link: {
      type: [String, Object],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Loader
  }
}
</script>
